var app = app || {};

(function() {
    function InnerBanner(el) {
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    InnerBanner.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {
            speed: 600,
            slidesPerView: 1,
            spaceBetween: 0,
            loop:false,
            touchRatio: 0,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // navigation: {
            //     nextEl: '.steps-button-next',
            //     prevEl: '.steps-button-prev',
            // },

            breakpoints: {
                320: {
                    touchRatio: 1,
                },
                640: {
                    touchRatio: 1,
                },
                641: {
                    touchRatio: 0,
                },
            }

        });
    }

    InnerBanner.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    InnerBanner.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.InnerBanner = InnerBanner;
})();
