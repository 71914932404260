var app = app || {};

(function() {
    function ProductSliderBtm(el) {
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    ProductSliderBtm.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {

            slidesPerView: 3,
            spaceBetween: 30, 
            loop: true,

            breakpoints: {

                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },

                600: {
                    slidesPerView: 1,
                },

                736: {
                    slidesPerView: 2,
                },

                1024: {
                    slidesPerView: 3,
                },

                1240: {
                    slidesPerView: 3,
                },

                1400: {
                    slidesPerView: 4,
                    spaceBetween: 30, 
                },

                1800: {
                    slidesPerView: 4,
                    spaceBetween: 30, 
                },

            },

            navigation: {
                nextEl: '.p-next',
                prevEl: '.p-prev',
            },

        });

        //this.swiper.slideTo(1, false,false);

        // if ( window.innerWidth < 1061  ) {
        //     this.swiper.slideTo(0, false,false);
        // } else {
        //     this.swiper.slideTo(1, false,false);
        // }

    }

    ProductSliderBtm.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    ProductSliderBtm.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.ProductSliderBtm = ProductSliderBtm;
})();
