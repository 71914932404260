var app = app || {};

app.stickyMenu = function (window) {

    var stickyMenu = jQuery('body');
    var scroll = window.scrollTop();

    if (scroll >= 200) {
        stickyMenu.removeClass('wr-hide').addClass("header-main__fixed");
    } else {
        stickyMenu.removeClass("header-main__fixed").addClass(stickyMenu);
    }

}