var app = app || {};

(function($, document, window) {
    'use strict'
    var $el = {},
        _instances = [];

    // Set Global Selector
    function cacheDom() {
        app.imgScrollOffset = jQuery(window).height() * .8;
        $el.bannerSlider = jQuery('.home-banner-slider');
        $el.innerBannerSlider = jQuery('.inner-banner');
        $el.productSlider = jQuery('.product-slider');
        $el.productSliderBtm = jQuery('.product-slider-btm');
        $el.gallerySlider = jQuery('.gallery-slider');
        $el.privateBrands = jQuery('.privateBrands-slider');
        $el.departmentSlider = jQuery('.department-slider');

        $el.mobileRecipes = jQuery('.slide-chef-pete');
        //$el.mobileMarketplace = jQuery('.marketplace-slider');

        // Recipes clear input field
        jQuery('.clearText').click(function(){ 
            jQuery("#recipeKeyword").val('');
        }); 
        jQuery(".select-option__inpt").focus(function(){ 
            jQuery(this).parent().removeClass("round");
            jQuery(this).parent().addClass("show-x-btn");
         
        }).blur(function(){
                jQuery(this).parent().removeClass("show-x-btn");
            jQuery(this).parent().addClass("round");
        });
         
      
        SocialShareKit.init({
            text: '',
        }); 

        jQuery(".chosen").chosen();  

        // Boostarap4 collapse
        //jQuery('.collapse').collapse()

        // SELECT YOUR LOCAL STORE
        // jQuery("#find-your-location").modal({
        //     backdrop: 'static',
        //     keyboard: false
        // });

        // Custom checkbox design
        // jQuery('input').iCheck({
        //     checkboxClass: 'icheckbox_square',
        //     radioClass: 'iradio_square',
        //     increaseArea: '20%'
        // });

        // Bootstrap Tab
        var triggerTabList = [].slice.call(document.querySelectorAll('#myTab a'))
            triggerTabList.forEach(function (triggerEl) {
            var tabTrigger = new bootstrap.Tab(triggerEl)

            triggerEl.addEventListener('click', function (event) {
                event.preventDefault()
                tabTrigger.show()
            })
        })

        var $locationHash = window.location.hash;
        if($locationHash.length > 0) {
            jQuery(".sub-menu li a").each(function() {
                var elem = jQuery(this);

                if(elem.data('link-hash') == $locationHash) {
                    elem.parent().addClass('child-active');
                }
            });
        }

        Splitting({
            target: "[data-splitting]",
            by: "words",
        });

        AOS.init({
            easing: 'ease-out-quart',
            offset: 130,
            delay: 0.4,
            once: true,
            duration: 1200,
        });

        jQuery('[data-fancybox]').fancybox();

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
            jQuery("body").addClass("ios-device");
        }
        if (navigator.userAgent.match(/Android/i)) {
            jQuery("body").addClass("android-device");
        }
        if (navigator.appVersion.indexOf("Win") != -1) {
            jQuery('body').addClass("win-os");
        }
        if (navigator.appVersion.indexOf("Mac") != -1) {
            jQuery('body').addClass("mac-os");
        }
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
            jQuery("html").addClass("ie11");
        }
        if (navigator.appVersion.indexOf("MSIE 10") !== -1) {
            jQuery("html").addClass("ie10-less");
        }

        var ie = ( !!window.ActiveXObject && +( /msie\s(\d+)/i.exec( navigator.userAgent )[1] ) ) || NaN;
        if (ie === 6 || ie === 7 || ie === 9 || ie === 9) {
            jQuery("html").addClass("ie10-less");
        }


    }

    function initDynamicComponents() {

        if($el.bannerSlider.length) {
            _instances.push(new app.HomeBanner($el.bannerSlider));
        }

        if($el.innerBannerSlider.length) {
            _instances.push(new app.InnerBanner($el.innerBannerSlider));
        }

        if($el.productSlider.length) {
            _instances.push(new app.ProductSlider($el.productSlider));
        }

        if($el.productSliderBtm.length) {
            _instances.push(new app.ProductSliderBtm($el.productSliderBtm));
        }

        if($el.gallerySlider.length) {
            _instances.push(new app.GallerySlider($el.gallerySlider));
        }

        if($el.mobileRecipes.length) {
            _instances.push(new app.MobileRecipes($el.mobileRecipes));
        }  

        if (window.innerWidth < 1025) { 
            
        }    
        // if($el.mobileMarketplace.length) {
        //     _instances.push(new app.MobileMarketplace($el.mobileMarketplace));
        // }   

        if($el.privateBrands.length) {
            _instances.push(new app.PrivateBrands($el.privateBrands));
        }

        if($el.departmentSlider.length) {
            _instances.push(new app.DepartmentSlider($el.departmentSlider));
        }
        
        

        // if ($el.benefitsSlider.length) {
        //     if (window.innerWidth < 959) {
        //         _instances.push(new app.BenefitsSlider($el.benefitsSlider));
        //     }
        // }

        _instances.push(new app.Custom());
    }

    function initStaticComponents() {
    
    }

    function loader() {
        var section = window.location.hash;
        if(section.length > 0) {
            if(jQuery(section).length > 0) {
                var topOffset = jQuery(section).offset().top - 200;
                jQuery("html, body").animate({
                    scrollTop: topOffset
                }, 0);
            }
        }
    }

    function domReady() {
        cacheDom();
        initDynamicComponents();
        setEvents();
        initStaticComponents();
        loader();

    }

    function setEvents() {
        // Tablet Start | Header Fixed 
        if (window.innerWidth > 991) {
            jQuery(window).on('scroll', function () {
                app.stickyMenu(jQuery(this));
            });
        }
        //app.whiteScreen(jQuery(this));
    }

    function windowLoad() {
        setTimeout(function() {

        }, 1000);
    }

    jQuery(document).ready(domReady);

    jQuery(window).on('load', windowLoad);

})(jQuery, document, window);
