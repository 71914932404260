var app = app || {};

(function() {
    function PrivateBrands(el) {
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    PrivateBrands.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {
            autoHeight: true, //enable auto height  
            centeredSlides: false,
            loop: true,
            slidesPerView: 1, 
            spaceBetween: 150,
            navigation: {
                nextEl: '.pb-next',
                prevEl: '.pb-prev',
            },

            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                }
            }

        });

       //this.swiper.slideTo(1, false,false);

        

    }

    PrivateBrands.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    PrivateBrands.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.PrivateBrands = PrivateBrands;
})();
