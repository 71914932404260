var app = app || {};

(function() {
    function MobileMarketplace(el) { 
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    MobileMarketplace.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {

            slidesPerView: 2, 
            spaceBetween: 8,
            centeredSlides: true, 

            navigation: {
                nextEl: '.p-next',
                prevEl: '.p-prev',
            },
            
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                1024: {
                    centeredSlides: false, 
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                1669: {
                    centeredSlides: false, 
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
            }

        });

        //this.swiper.slideTo(1, false,false);

    }

    MobileMarketplace.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    MobileMarketplace.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.MobileMarketplace = MobileMarketplace;
})();
