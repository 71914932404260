var app = app || {};

(function() {
    function DepartmentSlider(el) {
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    DepartmentSlider.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {
            autoHeight: true, //enable auto height  
            centeredSlides: false,
            loop: true,
            slidesPerView: 1,
            spaceBetween: 150,
            navigation: { 
                nextEl: '.department-next',
                prevEl: '.department-prev',
            },

            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 25,
                }
            }

        });

       //this.swiper.slideTo(1, false,false);

        

    }

    DepartmentSlider.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    DepartmentSlider.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.DepartmentSlider = DepartmentSlider;
})();
