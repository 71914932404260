var app = app || {};

(function() {
    function CarouselBanner(el) {
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    CarouselBanner.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {

            centeredSlides: true,
            slidesPerView: 1,
            loop: true,
            speed: 2000,
            autoplay: {
                delay: 5000, 
                disableOnInteraction: true,
            },

        });        

    }

    CarouselBanner.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    CarouselBanner.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.CarouselBanner = CarouselBanner;
})();

var bannerElement = document.querySelector('.carousel-text');
    if(bannerElement){
        var wrapper = bannerElement.querySelector('.swiper-wrapper');
        var banner = null;

        if (wrapper.children.length > 1) {
            banner = new app.CarouselBanner(bannerElement);
        }
    }
