function createMap(mapid,mapdata) {
    var styles = [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ];

    var styledMap = new google.maps.StyledMapType(styles,{name: "Map"});
    var mapOptions = {
        disableDefaultUI: true,
        scrollwheel: false,
        center: mapdata.center,
        zoom: mapdata.zoom,
        mapTypeControlOptions: {
            mapTypeIds:['map_style']
        }
    };
    mapdata.map = new google.maps.Map(document.getElementById(mapid),mapOptions);

    mapdata.map.mapTypes.set('map_style',styledMap);
    mapdata.map.setMapTypeId('map_style');

    var marker = new google.maps.Marker({
        icon: '/wp-content/themes/parisima/images/Parisma_Map_Pin.png',
        position: mapdata.center,
        map: mapdata.map,
        title: mapdata.title 
    });

    google.maps.event.addDomListener(window, "resize", function() {
        google.maps.event.trigger(mapdata.map, "resize");
        mapdata.map.setCenter(mapdata.center);
    });
}

function initialize() {
    var locations  = [];
    var homefilter = jQuery('.home-map-filter').find('li');

    homefilter.each(function (index, elem) {
        var $this = jQuery(this);
        locations[index] = { map: null, zoom: 14, center: new google.maps.LatLng($this.data('lat'), $this.data('long')), title: $this.text() };
    });

    createMap('google-map', locations[0]);
}

function loadScript() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBdfTyBuOh0Vx3Z0zoIKY9ORL72dT-kKKs&callback=initialize";
    document.body.appendChild(script);
}

// window.onload = loadScript;

jQuery(function($) {
    jQuery('.home-map-filter').on('click', 'li', function () {
        var location = jQuery(this);
        var map      = { map: null, zoom: 14, center: new google.maps.LatLng(location.data('lat'), location.data('long')), title: location.text() };

        createMap('google-map', map);
    });
});