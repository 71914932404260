var app = app || {};

(function() {
    function MobileRecipes(el) { 
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    MobileRecipes.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {

            breakpoints: {

                320: {
                    slidesPerView: 1,
                    touchRatio: 1,
                },

                520: {
                    slidesPerView: 1,
                    touchRatio: 1,
                },

                812: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                    touchRatio: 1,
                },

                992: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                    touchRatio: 1,
                },

                993: {
                    slidesPerView: 3, 
                    spaceBetween: 30,
                    centeredSlides: false,
                    autoHeight: false,
                    touchRatio: 0,
                },

            },

            navigation: {
                nextEl: '.p-next',
                prevEl: '.p-prev',
            }

        });

        //this.swiper.slideTo(1, false,false);

    }

    MobileRecipes.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    MobileRecipes.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.MobileRecipes = MobileRecipes;
})();
