var app = app || {};

(function() {
    function Custom() {
        this.createBounds();

        this.scrollTopage = jQuery('.footer-info__items a[href*=\\#], .footer-info__row-bottom a[href*=\\#], .product-menu-show a[href*=\\#], .menu-product-mobile a[href*=\\#], .read-write-reviews li a[href*=\\#]');
        
        this.mobileMenuNav = jQuery('.header-mobile .menu-item-has-children');
        this.toggleMenu = jQuery('.header__toggle');    

        //this.selectDropDownCat = jQuery('.dropdown-wrap-cat');
        this.selectDropDownTag = jQuery('.dropdown-wrap-tag .dropdown-trigger');
        this.clickSaveCat = jQuery('.btn-cat');
        this.clickSaveTag = jQuery('.btn-tag');
        this.telJS = jQuery('.tel-js');
        this.inputTel = jQuery('.wpcf7 [type="tel"]');
        this.intlTelInstance = [];
        this.covidBtn = jQuery('.banner__alert-close');  
        this.addToCartSpinner = jQuery('.single_add_to_cart_button');
        this.menuProdMobile = jQuery('.menu-product-mobile');
        this.init(); 
    }

    Custom.prototype.init = function () {
        var that = this;
        setTimeout(function() {

        }, 1000);

        this.telJS.keyup(function(e) {
            var intlInput = window.intlTelInputGlobals.getInstance(this);
            jQuery(this).parents('form').find('[name="phone_full"]').val(intlInput.getNumber());
        });

        // 
        if (this.addToCartSpinner.length > 0) {
            this.addToCartSpinner.on('click', function() {
                var spinner = that.addToCartSpinner;
                if (spinner.hasClass('disabled')) {
                    spinner.addClass('wc-loader-spinner');
                }
            }.bind(this));
        }

        // Recipes detailed page srollTo
        jQuery('.print-icons .icon-share a').click(function(e){
            e.preventDefault();
            var target = jQuery(jQuery(this).attr('href'));
            if(target.length){
              var scrollTo = target.offset().top;
              jQuery('html,body').animate({scrollTop:jQuery(this.hash).offset().top - 200}, 1000 );
            }
        });

        // Scrollto submenu
        var section = window.location.hash;
        if (this.scrollTopage.length > 0) {
            this.scrollTopage.on('click', function(event){
                jQuery('html,body').animate({scrollTop:jQuery(this.hash).offset().top - 200}, 1000 );
            });
        }

        // Covid Button
        if (this.covidBtn.length > 0) {
            this.covidBtn.on('click', function() {
                var covid = that.covidBtn;
                covid.parents('.banner__alert').addClass('kick');
            }.bind(this));
        }

        // Toggle Menu
        if (this.toggleMenu.length > 0) {
            this.toggleMenu.on('click', function() {
                var togMenu = that.toggleMenu;
                togMenu.parents('.header-main').toggleClass('header--active').find('.header--active__mobile--mobMenu .sub-menu').removeClass('show');
            }.bind(this));
        }

        // Select Dropdown Menu Category
        // if (this.selectDropDownCat.length > 0) {
        //     this.selectDropDownCat.on('click', function() {
        //         var togMenu = that.selectDropDownCat;
        //         togMenu.parents('.prod-category').toggleClass('toggle');
        //     }.bind(this));
        // }

        // Select Dropdown Menu Tag
        if (this.selectDropDownTag.length > 0) {
            this.selectDropDownTag.on('click', function() {
                var togMenu = that.selectDropDownTag;
                togMenu.parents('.dropdown-wrap-tag').toggleClass('toggle');
            }.bind(this));
        }

        // Click Save Button Tag Dropdown
        if (this.clickSaveCat.length > 0) {
            this.clickSaveCat.on('click', function() {
                var togMenu = that.clickSaveCat;
                togMenu.parents('.dropdown-wrap-cat').removeClass('toggle');
            }.bind(this));
        }

        // Click Save Button Cat Dropdown
        if (this.clickSaveTag.length > 0) {
            this.clickSaveTag.on('click', function() {
                var togMenu = that.clickSaveTag;
                togMenu.parents('.dropdown-wrap-tag').removeClass('toggle');
            }.bind(this)); 
        }

        // Product Menu Mobile
        if (this.menuProdMobile.length > 0) {
            this.menuProdMobile.on('click', function() {
                var prodMob = that.menuProdMobile;
                prodMob.parents('.header-main').removeClass('header--active');
            }.bind(this)); 
        }
        
        // Shop Menu dropdown trigger
        jQuery(".shop-menu, .shop-menu-show").mouseover(function () {
            jQuery(".shop-menu-show").addClass("visible-product"), 
            jQuery(".shop-menu").addClass("parent-menu")
        }).mouseleave(function () {
            jQuery(".shop-menu-show").removeClass("visible-product"), 
            jQuery(".shop-menu").removeClass("parent-menu")
        });

        // Product Menu dropdown trigger
        jQuery(".product-menu, .product-menu-show").mouseover(function () {
            jQuery(".product-menu-show").addClass("visible-product"), 
            jQuery(".product-menu").addClass("parent-menu")
        }).mouseleave(function () {
            jQuery(".product-menu-show").removeClass("visible-product"), 
            jQuery(".product-menu").removeClass("parent-menu")
        });

        // Save Menu dropdown trigger
        jQuery(".save-menu, .save-menu-show").mouseover(function () {
            jQuery(".save-menu-show").addClass("visible-product"), 
            jQuery(".save-menu").addClass("parent-menu")
        }).mouseleave(function () {
            jQuery(".save-menu-show").removeClass("visible-product"), 
            jQuery(".save-menu").removeClass("parent-menu")
        });

        // About Menu dropdown trigger
        jQuery(".about-menu, .about-menu-show").mouseover(function () {
            jQuery(".about-menu-show").addClass("visible-product"), 
            jQuery(".about-menu").addClass("parent-menu")
        }).mouseleave(function () {
            jQuery(".about-menu-show").removeClass("visible-product"), 
            jQuery(".about-menu").removeClass("parent-menu")
        });

        // Recipies Menu dropdown trigger
        jQuery(".recipes-menu, .recipes-menu-show").mouseover(function () {
            jQuery(".recipes-menu-show").addClass("visible-product"), 
            jQuery(".recipes-menu").addClass("parent-menu")
        }).mouseleave(function () {
            jQuery(".recipes-menu-show").removeClass("visible-product"), 
            jQuery(".recipes-menu").removeClass("parent-menu")
        });

        // Mobile Submenu
        if (this.mobileMenuNav.length > 0){
            this.mobileMenuNav.on('click', function (e) {
                var elem =  jQuery(this);
                var menuElem = elem.find('.sub-menu');
                var resetTrigger = !menuElem.hasClass('show');

                that.mobileMenuNav.find('.sub-menu').removeClass('show');
                that.mobileMenuNav.find('.m-close-menu').removeClass('rotate');
                that.mobileMenuNav.removeClass('active');
                elem.addClass('active');

                if(menuElem.length && resetTrigger) {
                    elem.find('.m-close-menu').addClass('rotate');
                    menuElem.addClass('show');
                    e.stopPropagation();
                    e.preventDefault();
                }
            });
        }

        // Search init popup
        if (this.siteSearchBlock) {
            this.siteSearchBlock.on('click', function () {
                var elem = that.siteSearchPopUp;

                if (elem.hasClass('active')) {
                    elem.removeClass('active');
                    elem.parent().removeClass('search_mob');
                } else {
                    elem.addClass('active');
                    elem.parent().addClass('search_mob');
                }

                that.siteSearchField.val('');

                setTimeout(function () {
                    that.siteSearchField.focus();
                }, 700);
            });
        }
 
    }

    Custom.prototype.intlTel = function (elem) {
        var instance = intlTelInput(elem, {
            initialCountry: "auto",
            nationalMode: false,
            autoPlaceholder: false,
            separateDialCode: true,
            geoIpLookup: function (success, failure) {
                jQuery.get("https://ipinfo.io", function () {
                }, "jsonp").always(function (resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "";
                    success(countryCode);
                });
            },
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.1/js/utils.js'
        });

        elem.addEventListener("countrychange", function() {
            var intlInput = window.intlTelInputGlobals.getInstance(this);
            jQuery(this).parents('form').find('[name="phone_full"]').val(intlInput.getNumber());
        });

        this.intlTelInstance.push(instance);
    }

    Custom.prototype.destroy = function () {
        this.toggleMenu.off('click');
        this.mobileMenuNav.off('click');
        //this.selectDropDownCat.off('click');
        this.selectDropDownTag.off('click');
        this.clickSaveTag.off('click');
        this.clickSaveCat.off('click');
        this.covidBtn.off('click');
    }

    Custom.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.Custom = Custom;
})();
