var shopList = jQuery(".card-shop__listing");
var quikView = jQuery(".quick-view");
var quikViewWrap = jQuery(".quick-view-wrap");
var productLoadMore = jQuery(".product-load-more");

var storeArrary = { "BLOOMFLD": 'Bloomfield', "CLIN0001": 'Clinton Twp.', "STCS0001": 'TBD', "TROY0001": 'Troy'}; 

if (quikViewWrap) {
  jQuery(document).on("click", ".quick-view-open", function (e) {
    var selectedProd = jQuery(this)
      .attr("href")
      .split("#")[1];

    jQuery(".quick-view").addClass("active");
    jQuery(".quick-view")
      .find("[data-id='" + selectedProd + "']")
      .addClass("show");
    e.preventDefault();
  });

  jQuery(document).on("click", ".quick-view-close", function (e) {
    jQuery(".quick-view").removeClass("active");
    jQuery(".quick-view-wrap").removeClass("show");
    e.preventDefault();
  });
}

var currentPage = 1;
var maxPages = 0;


function loadProducts(paged_num, type, product_category) {
  
  console.log('inside');

  var tag = [];

  tagEl.each(function (index) {
    var el = tagEl[index];
    if (el.checked) {
      tag.push(parseInt(el.value));
    }
  });

  var posts_per_page = 12;
  // var paged = jQuery('#paged').val();

  var data = {
    action: "get_shop_items",
    posts_per_page: posts_per_page,
    paged: paged_num,
    cat: jQuery('.hidden-prod-cat').val(),
    tag: tag,
    product_category: product_category
  };

  jQuery.ajax({
    type: "POST",
    url: "/wp-admin/admin-ajax.php",
    dataType: "json",
    data: data,
    success: function (data) {

      if (type == "load") {
        shopList.empty().append(data.product_list);
        // quikView.empty().append(data.quick_view);
      }

      if (type == "more") {
        shopList.append(data.product_list);
        // quikView.append(data.quick_view);
        // currentPage = parseInt(data.pagination.current);
      }

      total = parseInt(data.pagination.total);
      current = parseInt(data.pagination.current);

      productLoadMore.data('current-page', paged_num);

      if (total == current || total < 1) {
        productLoadMore.parent().addClass('hidden');
      } else {
        productLoadMore.parent().removeClass('hidden');
      }

      if(clickType == 'product-filter') {
        if ( total < 1) {
          shopList.append('<p clas="no-product-text">No products available.</p>');
        }
      }

      
      productLoadMore.html("Load More Products");
      // initialize_thwvs();
    },
  });
}

var saveChanges = jQuery(".save-changes");
var catEl = jQuery('.hidden-prod-cat');
var tagEl = jQuery('.dropdown-options [name="tag"]');
var recipeKeyword = jQuery("#recipeKeyword");
var clickType;

if (saveChanges) {
  jQuery(saveChanges).on("click", saveChanges, function (e) {
    clickType = jQuery(this).data('click-type');
    loadProducts(1, "load");
    shopList.empty().append("<p>Loading...</p>");
  });

  productLoadMore.click(function (event) {
    var product_category = jQuery(this).data('category');
    var current_page = jQuery(this).data('current-page');
    loadProducts(parseInt(current_page + 1), "more", product_category);
    productLoadMore.html("Loading...");
    event.preventDefault();
  });
}



var recipeList = jQuery("#recipeList .row");
var recipeLoadMore = jQuery(".recipe-load-more");

function loadRecipes(paged_num, type) {
  var cat = jQuery("#recipeCat").val();
  // var tag = jQuery("#recipeTag").val();
  var keyword = jQuery("#recipeKeyword").val();

  var posts_per_page = 12;
  // var paged = jQuery('#paged').val();
  var data = {
    action: "get_recipe_items",
    posts_per_page: posts_per_page,
    paged: paged_num,
    cat: cat,
    keyword: keyword,
    // tag: tag,
  };

  jQuery.ajax({
    type: "POST",
    url: "/wp-admin/admin-ajax.php",
    dataType: "json",
    data: data,
    success: function (data) {
      if (type == "load") {
        recipeList.empty().append(data.recipe_list);
      }

      if (type == "more") {
        recipeList.append(data.recipe_list);
        // currentPage = parseInt(data.pagination.current);
      }

      total = parseInt(data.pagination.total);
      current = parseInt(data.pagination.current);

      if (total == current) {
        recipeLoadMore.parent().addClass('hidden');
      } else {
        recipeLoadMore.parent().removeClass('hidden');
      }
      recipeLoadMore.html("Load more recipes");
    },
  });
}

recipeLoadMore.click(function (event) {
  currentPage = currentPage + 1;
  loadRecipes(parseInt(currentPage), "more");
  recipeLoadMore.html("Loading...");
  event.preventDefault();
});

jQuery(document).on("change", "#recipeCat", function (e) {
  jQuery('#recipes-filter-form').submit();
});


jQuery('#recipes-filter-form').find('input').keypress(function(e) {
  if(e.which == 10 || e.which == 13) {
    this.form.submit();
  }
});



jQuery(window).on("load", function () {

  var hashVal = window.location.hash.substr(1);
  
  if(hashVal.length && hashVal=='information' ) {
    if(jQuery('#collapseOne').length) {
      jQuery('#collapseOne').collapse('toggle');
    }
  }

  //show store popup only if no location is selected
  if (document.cookie.indexOf("visited=") == -1) {      
      jQuery("#find-your-location").modal("show");
      setCookie("visited", "1");  
  }

  jQuery('.recipe-review .wpcr3_reviews_holder').prepend('<div id="read-reviews">','</div>');

  // loadProducts(0, 'load',1502);

  if(window.location.pathname == '/checkout/'){
    if (document.cookie.indexOf("checkoutLoaded=") == -1) {      
      jQuery("#find-your-location").modal("show");
      setOneHourCookie("checkoutLoaded", "1");  
    }
  }

});

var cartShownLocation = false


if (jQuery(".card-shop__listing .card-shop__item").length < 6) {
  jQuery(".product-load-more")
    .parent()
    .hide();
}

if (jQuery("#recipeList .recipes__item").length <= 6) {
  jQuery(".recipe-load-more")
    .parent()
    .hide();
}

function setCookie(cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + 2160000000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}

function setOneHourCookie(cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + 1 * 3600 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}


// checkout dynamic
function getCheckoutCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

if (jQuery('.page-Checkout').length) {   

  var product_cat_type = getCheckoutCookie('product_category_type_checkout');
  var store_location = getCheckoutCookie('store_location');

  if(store_location && store_location.length) {
    jQuery('#pickup_location option').each(function() {
        if(storeArrary[store_location] == jQuery(this).val()) {
            jQuery(this).attr('selected','selected');
        }
    });
  }

  if(product_cat_type == 'gift-cards') {
    jQuery('#pickup_location option').each(function() {
      if(jQuery(this).val() != 'Gift Card Standard Mail') {
        jQuery(this).remove();
      }
    });  
  }

  if(product_cat_type == 'gourmet-to-go' || product_cat_type == 'gift-baskets') {
    jQuery('#pickup_location option').each(function() {
      if(jQuery(this).val() == 'Gift Card Standard Mail' || jQuery(this).val() == 'Gift Basket UPS Delivery' ) {
        jQuery(this).remove();
      }
    });  
  }



}

jQuery(function(){
  // bind change event to select
  jQuery('#product-listing-filter').on('change', function () {
      var url = jQuery(this).val(); // get selected value
      if (url.length > 0) { // require a URL
          window.location = url; // redirect
      }
      return false;
  });

  jQuery(".external-btn").click(function(e) {
      e.preventDefault(); 
      var link = jQuery(this).attr('href');
      var type = jQuery(this).data('btn-type');
      var selectStore = jQuery('.modal-content .select-store');

      jQuery("#find-your-location").modal("show");    
      selectStore.addClass('unset');
      selectStore.data('store-link', link);
      

      if( type == 'fresh-to-go') {
        selectStore.data('ext-store-type', 'fresh-to-go');
      }

      if( type == 'deli') {
        selectStore.data('ext-store-type', 'deli');
      }

  });

  jQuery(document).on('click','.modal-content .unset', function(e) {
      var storeLink = jQuery(this).data('store-link');
      var storeType = jQuery(this).data('ext-store-type');
      var fullLink;
    
      if(storeType == 'fresh-to-go') {
        fullLink = storeLink + jQuery(this).data('store-slug');
      }  
      if(storeType == 'deli') {
        fullLink = storeLink + jQuery(this).data('store-id');
      } 

      window.open(
        fullLink,
        '_blank'
      );
  });
});

jQuery(".checkout").submit(function (e) {
  // e.preventDefault();

  var submitButton = jQuery("#place_order");
  submitButton.addClass('disable-checkout');
  submitButton.addClass('wc-loader-spinner');

  submitButton.text("Please Wait...");
  return true;

});

function openDelivery(delivery, optionName) {
  var i, tabcontent, tablinks;

  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  document.getElementById(optionName).style.display = "block";
  delivery.currentTarget.className += " active";
}

// jQuery(document).ready(function($) {
//   var counter = 0
//   if(counter == 0){
//     $(document).on('wpcf7submit', function(event) {
//       counter++
//       var submitButton = $(event.target).find('button[type="submit"]');
//       submitButton.prop('disabled', true).text('Please Wait');
//       submitButton.addClass('wc-loader-spinner');
  
  
//       // Enable the submit button after a delay (e.g., 3 seconds)
//       setTimeout(function() {
//         submitButton.prop('disabled', false).text('Submit');
//         submitButton.removeClass('wc-loader-spinner');
//         counter--;
//       }, 3000);
//     });
//   }
  
// });

jQuery(".wpcf7-form").submit(function (e) {
  // e.preventDefault();

  var submitButton = jQuery('button[type="submit"]');
  submitButton.prop('disabled', true).text('Please Wait...');
  submitButton.addClass('wc-loader-spinner');
  // return true;
  setTimeout(function() {
    submitButton.prop('disabled', false).text('Submit');
    submitButton.removeClass('wc-loader-spinner');
    return true;
  }, 3000);
});