var app = app || {};

(function() {
    function GallerySlider(el) {
        this.createBounds();
        this.el = el;

        this.swiper = null;

        this.init();
    }

    GallerySlider.prototype.init = function () {
        var that = this;

        this.swiper = new Swiper(this.el, {

            centeredSlides: true,
            slidesPerView: 7,
            loop: true,
            speed: 1000,

            breakpoints: {
                320: {
                    slidesPerView: 2,
                },

                375: {
                    slidesPerView: 3,
                },

                540: {
                    slidesPerView: 3,
                },

                768:{
                    slidesPerView: 5,
                },

                1196: {
                    slidesPerView: 5,
                },
                

                // Desktop
                1441: {
                    slidesPerView: 7,
                    spaceBetween: 0,
                    centeredSlides: false,
                    loop: true,
                },

                1463: {
                    slidesPerView: 5,
                },

                1680: {
                    slidesPerView: 6,
                },

                1757: {
                    slidesPerView: 7,
                    centeredSlides: false,
                    spaceBetween: 0,
                    loop: true,
                },

                2560: {
                    slidesPerView: 9,
                },

            },

            autoplay: {
                delay: 5000, 
                disableOnInteraction: true,
            },

        });        

    }

    GallerySlider.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    GallerySlider.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.GallerySlider = GallerySlider;
})();
