var app = app || {};

(function() {
    function HomeBanner(el) {
        this.createBounds();
        this.el = el;
        this.swiper = null;
        this.init();
    }

    HomeBanner.prototype.init = function () {
        var that = this;
        var isMobile = window.innerWidth < 641; // Check if device is mobile  

        this.swiper = new Swiper(this.el, {
            speed: 600,
            slidesPerView: 1,
            spaceBetween: 0,
            loop: this.el.children.length > 1,
            keyboard: true,
            grabCursor: true,
            
            effect: bannerElement.dataset.slidertype,

            autoplay: this.el.children.length > 1 && isMobile ? false :{
                delay: 5000,
                disableOnInteraction: false,
                disableOnSingleSlide: true,
            },

            on: {
                slideChange: function () {
                    var currentSlide = this.slides[this.activeIndex];
                    var videos = currentSlide.querySelectorAll('video');

                    videos.forEach(function(video) {
                        if (video) {
                            this.autoplay.stop();
                            video.currentTime = 0;
                            video.play();
                            video.addEventListener('ended', function() {
                                this.autoplay.disableOnInteraction = true;
                                this.autoplay.start();
                            }.bind(this));
                        } else {
                            this.autoplay.disableOnInteraction = false;
                        }
                    }.bind(this));
                    
                },
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },      
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },      

        });
    }

    HomeBanner.prototype.destroy = function () {
        //destroy events

        this.swiper.destroy();
    }

    HomeBanner.prototype.createBounds = function () {
        var that = this;
        ['init', 'destroy']
            .forEach(function (fn) {
                that[fn] = that[fn].bind(that)
            });
    }

    app.HomeBanner = HomeBanner;
})();


    var bannerElement = document.querySelector('.home-banner');
    if(bannerElement){
        var wrapper = bannerElement.querySelector('.swiper-wrapper');
        var banner = null;

        if (wrapper.children.length > 1) {
            banner = new app.HomeBanner(bannerElement);
        }
    }

   
